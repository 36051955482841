import { Card, CardContent, Typography } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import * as animationData from "../lotties/404.json";
import Lottie from "react-lottie";
import { useEffect } from "react";

function NotFound() {

  useEffect(() => {
    document.title = "Page not found";
  }, [])
  

  const location = useLocation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Card
      style={{
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <CardContent>
        <div className="root">
          <Typography variant="h3">Page not found</Typography>
          <Typography variant="h5">Reason: {location.state.error}</Typography>
          <Lottie options={defaultOptions} height={400} width={600}></Lottie>
        </div>
      </CardContent>
    </Card>
  );
}

export default NotFound;
