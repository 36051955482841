import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import PaymentLink from "./components/Paymentlink";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OtpScreen from "./components/OtpScreen";
import NotFound from "./components/NotFound";
import PaymentStatus from "./components/PaymentStatus";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/:paylinkId/:txnNo" element={<App />} />
      <Route path="/ppl" element={<PaymentLink />} />
      <Route path="otp" element={<OtpScreen />} />
      <Route path="status" element={<PaymentStatus />} />
      <Route path="404" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);
