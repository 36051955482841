import { Card, CardContent, Typography } from "@material-ui/core";
import * as successAnimationData from "../lotties/success.json";
import * as failedAnimationData from "../lotties/fail.json";
import Lottie from "react-lottie";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function PaymentStatus() {
  const location = useLocation();
  const isSuccess = location.state.responseCode === "0";
  const title = isSuccess ? "Payment Success" : "Payment Failed";

  useEffect(() => {
    document.title = title;
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
    console.log('location.state.responseCode',location.state.responseCode)
    console.log('isSuccess',isSuccess)
    console.log('title',title)
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: isSuccess ? successAnimationData : failedAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Card
      style={{
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <CardContent>
        <div className="root">
          <Typography variant="h3">{title}</Typography>
          <Typography variant="h5" gutterBottom>
            Transaction ID: {location.state.txnNo}
          </Typography>
          <Lottie options={defaultOptions} height={400} width={400}></Lottie>
        </div>
      </CardContent>
    </Card>
  );
}

export default PaymentStatus;
