import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiRequest } from "../services/ApiRequest";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/lab/Alert";
import Lottie from "react-lottie";
import * as animationData from "../lotties/loading.json";
let baseUrl=process.env.REACT_APP_BASE_API_URL;



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles({
  primaryButton: {
    background: "#e21c15",
    color: "white",
    borderRadius: "25px",
    marginTop: 30,
    marginBottom: 30,
    padding: 30,
  },
  productDetails: {
    background: "linear-gradient(180deg,#605e5e 0,#1e1e1e)",
    color: "#fff",
    borderRadius: 15,
    marginTop: 30,
    marginBottom: 30,
  },
  appBar: {
    backgroundColor: "white",
    height: "80px",
  },
  logo: {
    maxWidth: 160,
  },
  snackBar: {

    // position: "absolute",
    // top: "30%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
  },
  loader: {
    position:"absolute",
    left : "0",
    top: '0',
    background: "#fff",
    width: '100%',
    height: '100%',
    textAlign: 'center',
    zIndex:'999'


  }
});

function OtpScreen() {
  const ref = useRef(null);
  const location = useLocation();
  const css = useStyles();
  const navigate = useNavigate();

  const [isOtpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [timer, setTimer] = useState("00");
  const [open, setOpen] = useState(false); //snackbar
  const totalCalculationVO = location.state.amountData;
  const vendorCode = location.state.vendorCode;
  const [isOtpNull, setOtpNull] =useState('true');
  const [loader, setLoader] = useState(false); //snackbar
  const requestType = location.state.requestType;
  const [checkBox, setCheckBox] = useState(false); 
  const [count, setCount] = useState(0);
  const [countResend, setCountResend] = useState(1);


  const handleToClose = (event, reason) => {
    if ("clickaway" == reason) return;
    setOpen(false);
  };

  const handleClickEvent = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2500);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  useEffect(() => {
    getIP();
    document.title = "OTP verification";
    clearTimer(getDeadTime());
    // block back click 
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });

  }, []);
 
  const [otp, setOtp] = useState("");
  const [panNo, setPanNo] = useState("");
  const [ip, setIP] = useState('');
  const [working, setWorking] = useState(false);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    return {
      total,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      if (seconds === 0) {
        setTimer("Resend OTP");
      } else {
        let resetTxt =
          "Resend OTP in " + (seconds > 9 ? seconds : "0" + seconds);
        setTimer(resetTxt);
      }
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("30");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (ref.current) clearInterval(ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 30);
    return deadline;
  };

  const onClickResend = () => {

    console.log(timer);

    if (timer === "Resend OTP") {
      setCountResend(countResend + 1)
      if(requestType == 'BRANDEMINBFC' ){
        resendNbfcOTP();
      }
      else{
        sendOtp();
    }
  }
  };

  const sendOtp = () => {
    apiRequest
      .post(
        `${baseUrl}user/wrapperAPI/sendOtpAccountVerification`,
        {
          data: {
            indusindRespId: location.state.requestId,
            mobile: location.state.mobileNo,
            requestNumber:location.state.requestNumber,
            digitalVendorCode : location.state.vendorCode,
          
          },
          method: "POST",
          type: "payment",
          url: "sendOtpAccountVerification",
        }
      )
      .then(function (response) {
        const otpRes = response.data;

        if (otpRes && otpRes.responseCode === "0") {
          clearTimer(getDeadTime());
        } else {
          console.log("Error res");
        }
      });
  };

  const resendNbfcOTP = () => {
    apiRequest
      .post(
        `${baseUrl}user/wrapperAPI/resendOTPNbfc`,
        {
          data: {
            requestNumber:location.state.requestNumber,
          },
          method: "POST",
          type: "payment",
          url: "resendOTPNbfc",
        }
      )
      .then(function (response) {
        const otpRes = response.data;

        if (otpRes && otpRes.responseCode === "0") {
          clearTimer(getDeadTime());
        } else {
          console.log("Error res");
          setOtpErrorMessage(otpRes.statusMessage);
        }
      });
  };
  
  const getIP =  () => {
   apiRequest.get('https://geolocation-db.com/json/').then(res=>{
    if (res.data) {
      console.log(res);
      setIP(res.data.IPv4)
    }
   })

  }
  const validateOtp = () => {
    setLoader(true)
    console.log(ip,location.state.requestNumber,working);
    setWorking(true);
    apiRequest
      .post(
        `${baseUrl}user/wrapperAPI/validateOtp`,
        {
          data: {
            indusindRespId: location.state.requestId,
            mobile: location.state.mobileNo,
            otp: otp,
            panNumber : panNo.toUpperCase(),
            requestNumber : location.state.requestNumber,
            digitalVendorCode : location.state.vendorCode,
            ipAddress : ip
          },
          method: "POST",
          type: "payment",
          url: "validateOtp",
        }
      )
      .then(function (response) {
        const otpRes = response.data;

        if (otpRes && otpRes.responseCode === "0") {
          markPaymentStatus(otpRes.responseMessage);
        } else {
          setLoader(false);
          setOtpError(true);
          setWorking(false);
          setOtpErrorMessage(otpRes.responseMessage);
          handleClickEvent();
        }
      });
  };

  const validateOtpForNBFC = () => {
    setLoader(true)
    apiRequest
      .post(
        `${baseUrl}user/wrapperAPI/getValidatePurchase`,
        {
          data: {
            mobile: location.state.mobileNo,
            otp: otp,
            requestNumber : location.state.requestNumber,
            txnRefNumber: location.state.txnNo,
          },
          method: "POST",
          type: "payment",
          url: "getValidatePurchase",
        }
      )
      .then(function (response) {
        const otpRes = response.data;
        if (otpRes && otpRes.responseCode === "0") {
          navigate("/status", {
            replace: true,
            state: {
              responseCode: otpRes.responseCode,
              txnNo: location.state.txnNo,
            },
          });
      
        } else {
          setOtpError(true);
          setOtpErrorMessage(otpRes.statusMessage);
          handleClickEvent();
        }
        setLoader(false);
      });
  };


  const markPaymentStatus = (responseMessage) => {
    setLoader(true);
    setWorking(true);
    apiRequest
      .post(
        `${baseUrl}user/wrapperAPI/makePayloadForIndusind`,
        {
          data: {
            indusindRespId: location.state.requestId,
            txnRefNumber: location.state.txnNo,
            cardlessVendorCode: location.state.vendorCode,
            mobile: location.state.mobileNo,
            requestNumber:location.state.requestNumber,
            ipAddress:ip,
            transactionStatus:responseMessage

          },
          method: "POST",
          type: "payment",
          url: "makePayloadForIndusind",
        }
      )
      .then(function (response) {
        const statusRes = response.data;
        if (statusRes) {
          setLoader(false);
          setWorking(false);
          navigate("/status", {
            replace: true,
            state: {
              responseCode: statusRes.responseCode,
              txnNo: location.state.txnNo,
            },
          });
        } else {
          setLoader(false);
          setWorking(false);
          console.log("Error res");
        }
      });
  };

  const otpChanges = (e) => {
    setOtp(e.target.value.replace(/[^0-9]*/g, ''));
    console.log(otp);
    if(e.target.value.length == 6){
      setOtpNull(false)
    }
    else{
      setOtpNull('true')
    }
  };

  const panChanges = (e) => {
    console.log(e.target.value);
    setPanNo(e.target.value.toUpperCase());
  };

 const handleCheckBoxChange = (e) => {
    let isChecked = e.target.checked;
    setCheckBox(e.target.checked)
  }





  const onSubmitClick = (e) => {
    setCount(count + 1)
    if(requestType == 'BRANDEMINBFC'){
      validateOtpForNBFC();
    }else{
      validateOtp();
    }
    console.log('count',count)
    if(count == 4 ){
     //markPaymentStatus('Failed');
      navigate("/status", {
        replace: true,
        state: {
          responseCode: 'FAILED',
          txnNo: location.state.txnNo,
        },
      });
    }

  };

  return (
    <div className="parent">
      <AppBar className={css.appBar}>
        <Toolbar>
          <Box
            component="img"
            sx={{
              height: 75,
            }}
            alt="Logo"
            src={require("../assets/images/logo.png")}
          />
        </Toolbar>
      </AppBar>
      <Card
        style={{
          margin: 20,
          backgroundColor: "white",
          borderRadius: 20,
          paddingLeft: 20,
          paddingRight: 20,
          marginTop: "85px",
        }}
      >
        <CardContent>
          <div className="root">
            <Typography variant="h3">Please Enter OTP</Typography>
            <Typography variant="h5" gutterBottom>
              OTP sent on: {location.state.mobileNo}
            </Typography>
            <Box
              style={{
                width: "90%",
                marginTop: 20,
              }}
              textAlign="center"
              m={1}
            >
              <Grid className={css.productDetails} container spacing={2}>
                <Grid item xs={12}>
                  <Box textAlign="left" m={1}>
                    <Typography variant="h5" gutterBottom>
                      Payment Details
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign="left" m={1}>
                    <Typography variant="subtitle1" gutterBottom>
                      Merchant name
                    </Typography>
                  </Box>
                  <Box textAlign="left" m={1}> 
                    <Typography variant="h6" gutterBottom>
                      { location.state.merchantName }
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign="left" m={1}>
                    <Typography variant="subtitle1" gutterBottom>
                      Transaction ID
                    </Typography>
                  </Box>
                  <Box textAlign="left" m={1}>
                    <Typography variant="h6" gutterBottom>
                      {location.state.txnNo}
                    </Typography>
                  </Box>
                </Grid>
                { (location.state.brandName != 'NONBRAND' && location.state.brandName != 'EAGLE') ?  <Grid item xs={6}>
                  <Box textAlign="left" m={1}>
                    <Typography variant="subtitle1" gutterBottom>
                      Product
                    </Typography>
                  </Box>
                  <Box textAlign="left" m={1}>
                    <Typography variant="h6" gutterBottom>

                      {location.state.brandName} {location.state.sku}
                    </Typography>
                  </Box>
                </Grid>  :null}

                <Grid item xs={6}>
                  <Box textAlign="left" m={1}>
                    <Typography variant="subtitle1" gutterBottom>
                      Bank name
                    </Typography>
                  </Box>
                  <Box textAlign="left" m={1}>
                    <Typography variant="h6" gutterBottom>
                      {location.state.bankName} 
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                width: "90%",
                marginTop: 20,
              }}
              textAlign="center"
              m={1}
            >
              <TextField
                style={{
                  width: "100%",
                }}
                required
                // error={isOtpError}
                // helperText={otpErrorMessage}
                id="outlined-basic"
                label="Enter OTP"
                variant="outlined"
                value={otp} 
                inputProps={{ maxLength: 6 }}
                onChange={otpChanges}
              
              />
            </Box>

            { (vendorCode != 'Kotak' && vendorCode != null && vendorCode != 'Hdfc' )  ?  <Box
              style={{
                width: "90%",
                marginTop: 20,
              }}
              textAlign="center"
              m={1}
            >
              <TextField
                style={{
                  width: "100%",
                }}
                required
                id="outlined-basic"
                label="Enter PAN"
                variant="outlined"
                onChange={panChanges}
              />
            </Box> : null }

              { requestType == 'BRANDEMINBFC' ? <div style={{textAlign:'left'}}> <input type='checkbox' onChange={handleCheckBoxChange}/>  I confirm the receipt of 'TVS Credit Services Limited' Pre-approved Loan <a style={{color:'blue', textDecoration:'none'}} href="https://merchant-documents.s3.ap-south-1.amazonaws.com/tnc-documents/NBFC-Terms-and-condition.pdf" target='_blank'>Terms and conditions governing this loan
              Received, Read &amp; Understood</a> </div> : null
              }

            { (vendorCode != 'Kotak' && vendorCode != null && vendorCode !='Hdfc'  || requestType == 'BRANDEMINBFC') ? 
            <Box
               style= { countResend < 4  ? { width:'90%', marginTop: '20'} : { width:'90%', marginTop: '20', display: 'none'}}
              textAlign="right"
              m={1}
              onClick={onClickResend}
            >
                <Typography
                  style={{ color: "#1368ec", textDecoration: "underline" }}
                  display="inline"
                >
                  {timer}
                </Typography>
              </Box>  :null 
            }


          </div>


          {requestType == 'BRANDEMINBFC' ? 
            <Box textAlign="center" m={1} >
            <Button
              disabled={isOtpNull || !checkBox}
              variant="contained"
              className={css.primaryButton}
              onClick={onSubmitClick}
              
            >
              <Typography variant="h4">
                PAY ₹{totalCalculationVO.swipeAmount}
              </Typography>
            </Button>
            </Box> : 

            <Box textAlign="center" m={1}>
            <Button
              disabled={isOtpNull}
              variant="contained"
              className={css.primaryButton}
              onClick={onSubmitClick}
              
            >
              <Typography variant="h4">
                PAY ₹{totalCalculationVO.swipeAmount}
              </Typography>
            </Button>
            </Box>

            }

        {requestType == 'BRANDEMINBFC' ? 
          <div> Maximum Retry limit is 3 and  You have entered {count} times. </div>  
          :null
        }
        </CardContent>
      </Card>
      { loader  ?
      <div className={css.loader} style={{"display": "block","position": "fixed","top": 0,"left": 0, "zIndex": 999}}>
      <Lottie options={defaultOptions} height={400} width={400}></Lottie>
      <h3>Please Wait...</h3>
      </div>
      :null
      }
      <Snackbar className={css.snackbar} open={open} anchorOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      >
        <Alert variant="filled" onClose={handleToClose} severity="error">
          {otpErrorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default OtpScreen;
