const Footer = () => {
  <footer
    className="footer"
    style={{
      position: "absolute",
      background: "#cfcfcf",
      padding: "20px",
      right: 0,
      left: 0,
      bottom: 0,
    }}
  >
    <p>Powered by Benow</p>
  </footer>;
};

export default Footer;
