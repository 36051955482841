import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/node_modules/@material-ui/styles";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/Paymentlink.css";
import { apiRequest } from "../services/ApiRequest";
import { formatter } from "../services/Util";
import Footer from "./Footer";
let Scroll = require("react-scroll");
let baseUrl=process.env.REACT_APP_BASE_API_URL;
let Element = Scroll.Element;

const useStyles = makeStyles({
  productDetails: {
    background: "#1e97f4",
    borderRadius: 15,
    marginTop: 20,
  },
  emiDetails: {
    background: "#000",
    color: "#fff",
    marginTop: 30,
    borderRadius: 15,
  },
  transactionDetails: {
    background: "linear-gradient(180deg,#605e5e 0,#1e1e1e)",
    color: "#fff",
    borderRadius: 15,
    marginTop: 30,
    marginBottom: 30,
  },
  coloredList: {
    color: "#787878",
    paddingLeft: "20px",
  },
  root: {
    overflowY: "scroll",
  },
  primaryButton: {
    background: "#e21c15",
    color: "white",
    borderRadius: "25px",
    marginTop: 30,
    marginBottom: 30,
    padding: 30,
  },
});

function PaymentLink() {
  useEffect(() => {
    document.title = "Payment Summary";
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const css = useStyles();
  const totalCalculationVO = location.state.amountData;

  const sendOtp = (mobileNo, txnNo) => {
    apiRequest
      .post(
        `${baseUrl}user/wrapperAPI/sendOtpAccountVerification`,
        {
          data: { indusindRespId: location.state.requestId, 
            mobile: mobileNo,
            requestNumber:location.state.requestNumber,
            digitalVendorCode : location.state.vendorCode,
           },
          method: "POST",
          type: "payment",
          url: "sendOtpAccountVerification",
        }
      )
      .then(function (response) {
        const otpRes = response.data;

        if (otpRes) {
          navigate("/otp", {
            replace: true,
            state: {
              mobileNo: location.state.mobileNo,
              requestId: location.state.requestId,
              vendorCode: location.state.vendorCode,
              requestNumber:location.state.requestNumber,
              digitalVendorCode : location.state.vendorCode,
              txnNo: txnNo,
            },
          });
        } else {
          console.log("Error res");
        }
      });
  };

  const startPaymentProcess = (mode) => {
    console.log(location.state.mobileNo);
    apiRequest
      .post(`${baseUrl}sdk/startPaymentProcess`, {
        paylinkid: location.state.id,
        email: "",
        mobileNo: location.state.mobileNo,
        payamount: totalCalculationVO.swipeAmount,
        convenienceFee: 0,
        convenienceGst: 0,
        emiDuration: location.state.emiDuration,
        firstName: "",
        lastName: "",
        merchantVPA: location.state.merchantName,
        merchantcode: location.state.merchantCode,
        merchantname: "", // check with Mangesh
        paytype: 21, // to confirm with Mangesh
        phone: location.state.mobileNo,
        products: null,
        resident: true,
        salutation: "",
        til: null,
        tr: "",
      })
      .then(function (response) {
        const txnRes = response.data;
        const txnNo = txnRes.transactionRef;
        if (txnRes && txnNo) {
          sendOtp(location.state.mobileNo, txnNo);
        } else {
          console.log("TXN error");
        }
      });
  };

  const onPayClick = () => {
    console.log("Vendor code", location.state.vendorCode);
    const requestType = location.state.requestType;
    console.log("requestType", requestType);
    if (requestType && requestType === "DIGITALFINANCE") {
      startPaymentProcess();
    } else {
      console.log("Error");
    }
  };

  return (
    <div>
      <Card
        style={{
          margin: 20,
          backgroundColor: "white",
          borderRadius: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <CardContent>
          <div className="root">
            <Typography variant="h3">Brand Cardless EMI</Typography>

            {/* Product details */}
            <Grid className={css.productDetails} container spacing={2}>
              <Grid item xs={12}>
                <Box textAlign="left" m={1}>
                  <Typography variant="h5" gutterBottom>
                    Product Details
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="left" m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    Brand name
                  </Typography>
                </Box>
                <Box textAlign="left" m={1}>
                  <Typography variant="h6" gutterBottom>
                    {location.state.brandName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="left" m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    Invoice number
                  </Typography>
                </Box>
                <Box textAlign="left" m={1}>
                  <Typography variant="h6" gutterBottom>
                    {location.state.invoiceNumber}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="left" m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    Model name
                  </Typography>
                </Box>
                <Box textAlign="left" m={1}>
                  <Typography variant="h6" gutterBottom>
                    {location.state.modelName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="left" m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    Bank name
                  </Typography>
                </Box>
                <Box textAlign="left" m={1}>
                  <Typography variant="h6" gutterBottom>
                    {location.state.bankName}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="left" m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    SKU
                  </Typography>
                </Box>
                <Box textAlign="left" m={1}>
                  <Typography variant="h6" gutterBottom>
                    {location.state.sku}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box textAlign="left" m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    Payment ID
                  </Typography>
                </Box>
                <Box textAlign="left" m={1}>
                  <Typography variant="h6" gutterBottom>
                    {location.state.id}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* EMI details */}
            <Grid className={css.emiDetails} container spacing={2}>
              <Grid item xs={12}>
                <Box textAlign="left" m={1}>
                  <Typography variant="h5" gutterBottom>
                    EMI & Cashback Details
                  </Typography>
                </Box>
              </Grid>
              {/* 1st row */}
              <Grid item xs={4}>
                <Box textAlign="center" m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    Amount
                  </Typography>
                </Box>
                <Box textAlign="center" m={1}>
                  <Typography variant="h6" gutterBottom>
                    ₹
                    {totalCalculationVO && totalCalculationVO.warrantyAmount > 0
                      ? formatter.format(
                          totalCalculationVO.amount +
                            totalCalculationVO.warrantyAmount
                        )
                      : formatter.format(totalCalculationVO.amount)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box textAlign="center" m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    Total Cashback
                  </Typography>
                </Box>
                <Box textAlign="center" m={1}>
                  <Typography variant="h6" gutterBottom>
                    ₹
                    {formatter.format(
                      totalCalculationVO.instantCashbackValue +
                        totalCalculationVO.productCashback
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box textAlign="center" m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    Loan Amount
                  </Typography>
                </Box>
                <Box textAlign="center" m={1}>
                  <Typography variant="h6" gutterBottom>
                    ₹
                    {formatter.format(
                      totalCalculationVO && totalCalculationVO.loanAmount
                        ? totalCalculationVO.loanAmount
                        : 0
                    )}
                  </Typography>
                </Box>
              </Grid>
              {/* 2nd row */}
              <Grid item xs={4}>
                <Box textAlign="center" m={1}>
                  <Typography margin="0" variant="subtitle1" gutterBottom>
                    Interest Amount
                  </Typography>
                </Box>
                <Box textAlign="center" m={1}>
                  <Typography variant="h6" gutterBottom>
                    ₹
                    {formatter.format(
                      totalCalculationVO && totalCalculationVO.totalInterest
                        ? totalCalculationVO.totalInterest
                        : 0
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box textAlign="center" m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    Tenure
                  </Typography>
                </Box>
                <Box textAlign="center" m={1}>
                  <Typography variant="h6" gutterBottom>
                    {totalCalculationVO && totalCalculationVO.duration} months @
                    {(totalCalculationVO && totalCalculationVO.rate) || 0}%
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box textAlign="center" m={1}>
                  <Typography variant="subtitle1" gutterBottom>
                    EMI/Month
                  </Typography>
                </Box>
                <Box textAlign="center" m={1}>
                  <Typography variant="h6" gutterBottom>
                    ₹
                    {formatter.format(
                      totalCalculationVO && totalCalculationVO.totalMonthlyEmi
                        ? totalCalculationVO.totalMonthlyEmi
                        : 0
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* Transaction details */}
            <Grid className={css.transactionDetails} container spacing={2}>
              <Grid item xs={12}>
                <Box textAlign="left" m={1}>
                  <Typography variant="h5" gutterBottom>
                    Transaction Amount
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box textAlign="left" m={1}>
                  <Typography variant="h4" gutterBottom>
                    ₹
                    {formatter.format(
                      totalCalculationVO && totalCalculationVO.swipeAmount
                        ? totalCalculationVO.swipeAmount
                        : 0
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* Offer details */}
            <Element name="container" className="element" id="containerElement">
              <Box textAlign="left" m={1}>
                <Typography variant="h5" gutterBottom>
                  Offer Details:
                </Typography>
              </Box>
              <ul className={css.coloredList}>
                <li>
                  <Box textAlign="left" m={1}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      I have been offered the choice of normal as well as EMI
                      for this purchase and I have chosen EMI.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box textAlign="left" m={1}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      I have fully understand and accept the terms of EMI scheme
                      and applicable charges mentioned.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box textAlign="left" m={1}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      EMI conversion subject to Bank's or Lenders discretion.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box textAlign="left" m={1}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      GST applicable on Interest and Processing fees.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box textAlign="left" m={1}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      I understand that the cashback as above is being provided
                      by the respective merchant/manufacturer/issuer depending
                      on the offer.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box textAlign="left" m={1}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      I understand that the cashback as above being provided may
                      take upto 90 days of processing by
                      merchant/manufacturer/issuer and will be credited to me by
                      the issuer only on receipt of the applicable cashback from
                      the merchant/manufacturer.
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box textAlign="left" m={1}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      ICICI Amazon Card benefits are restricted to Brand
                      benefits only. ICICI Bank Cashback offers will not apply
                      to this Card
                    </Typography>
                  </Box>
                </li>
                <li>
                  <Box textAlign="left" m={1}>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Offer is available only on Retail Cards. Offer is not
                      available on Corporate / Business Cards.
                    </Typography>
                  </Box>
                </li>
              </ul>
            </Element>
          </div>

          <Box textAlign="center" m={1}>
            <Button
              variant="contained"
              className={css.primaryButton}
              onClick={onPayClick}
            >
              <Typography variant="h4">AGREE AND PAY</Typography>
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Footer />
    </div>
  );
}

export default PaymentLink;
