import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./App.css";
import { apiRequest } from "./services/ApiRequest";
import Lottie from "react-lottie";
import * as animationData from "./lotties/loading.json";
import Footer from "./components/Footer";
let baseUrl=process.env.REACT_APP_BASE_API_URL;

function App() {
  const { paylinkId, txnNo } = useParams();
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  let payData;
  useEffect(() => {
    document.title = "Loading...";

    if (paylinkId && paylinkId != null && txnNo && txnNo !=null) {
      const header ={
        'X-AUTHORIZATION' : 'BENOW'
      }
      apiRequest
        .post(`${baseUrl}sdk/getPaymentLinkDetailsV2`, {
          campaignId: paylinkId,
        },header)
        .then(function (response) {
          payData = response.data;
          sessionStorage.setItem("bnMRc", JSON.stringify(payData));
          // console.log(`value of i is ${setHeader()}`)
          // setHeader();
          if (payData.status === "EXPIRED") {
            navigate("/404", {
              replace: true,
              state: {
                error:
                  "This payment link is expired, please try again with a new link",
              },
            });
          } else {
            getTotalCalculationVo();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      navigate("/404", {
        replace: true,
        state: {
          error: "Required parameters not found",
        },
      });
    }
  }, []);

  const processBrandEmi = (totalCalData) => {
    const amountData = JSON.parse(totalCalData.totalCalculation);

    apiRequest
      .post(`${baseUrl}sdk/processBrandEmiRequest`, {
        amount: amountData.amount,
        swipeAmount: amountData.swipeAmount,
        requestNumber: payData.requestNumber,
        tr: payData.invoiceNumber,
        remarks: "",
        merchantCode: payData.merchantCode,
        currEvent: "PAYMENTLINKOPENED",
        brandProductVO: payData.brandProductVO,
      })
      .then(function (response) {
        const emiDataRes = response.data;
        if (emiDataRes && emiDataRes.currEvent) {
          navigate("/ppl", {
            replace: true,
            state: {
              id: paylinkId,
              brandName: payData.brandProductVO.brandCode || "NA",
              invoiceNumber: payData.invoiceNumber || "NA",
              modelName: payData.brandProductVO.productDesc || "NA",
              bankName: payData.brandProductVO.bankDesc || "NA",
              sku: payData.brandProductVO.productCode || "NA",
              amountData: amountData,
              requestType: payData.brandProductVO.payRequestType,
              vendorCode: payData.digitalVendorCode,
              mobileNo: payData.mobileNumber,
              requestId: payData.indusindId,
              emiDuration: payData.brandProductVO.duration,
              merchantVPA: payData.merchantName,
              merchantCode: payData.merchantCode,
              requestNumber: payData.requestNumber,
            },
          });
        } else {
          getTotalCalculationVo();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getTotalCalculationVo = () => {
    apiRequest
      .post(
        `${baseUrl}user/wrapperAPI/getTotalCalculationVO`,
        {
          data: { requestNumber: payData.requestNumber },
          method: "POST",
          type: "payment",
          url: "getTotalCalculationVO",
        }
      )
      .then(function (response) {
        const totalCalData = response.data;
        console.log(totalCalData);

        if (totalCalData) {
          // processBrandEmi(totalCalData);
          goToOtp(totalCalData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const goToOtp = (totalCalData) => {
    const amountData =  JSON.parse(totalCalData && totalCalData.totalCalculation);
    navigate("/otp", {
      replace: true,
      state: {
        mobileNo: payData.mobileNumber,
        requestId: payData.indusindId,
        vendorCode: payData.digitalVendorCode,
        txnNo: txnNo,
        amountData: amountData,
        merchantCode: payData.merchantCode,
        brandName: payData.brandProductVO.brandCode || "NA",
        bankName: payData.brandProductVO.bankDesc || payData.brandProductVO.pgBankCode ||   "NA",
        sku: payData.brandProductVO.productCode || "NA",
        requestNumber: payData.requestNumber,
        merchantName: payData.merchantName,
        requestType: payData.brandProductVO.payRequestType,
        
      },
  
    });
    console.log('state',payData)
  };

  return (
    <div className="App">
      <Lottie options={defaultOptions} height={400} width={400}></Lottie>
      <h3>Please Wait...</h3>
      <Footer />
    </div>
  );
}

export default App;
