import axios from "axios";
let baseUrl=process.env.REACT_APP_BASE_API_URL;
export const apiRequest = axios.create({
  baseUrl: `${baseUrl}`,
  });
  apiRequest.interceptors.request.use(
    function(config) {
      let user_data =sessionStorage.getItem('bnMRc')
      console.log(user_data);
      config.headers['X-AUTHORIZATION'] = 'BENOW';
      if (user_data) {
        user_data = JSON.parse(user_data)
        config.headers['X-EMAIL'] = '';
        config.headers['X-Amzn-Trace-Id'] = '';
        if (user_data && user_data.jwtToken) {
          config.headers['X-AUTHORIZATION'] = `${user_data.jwtToken}`;
        }
        if (user_data && user_data.merchantCode) {
          config.headers['X-EMAIL'] = `${user_data.merchantCode}`;
          config.headers['X-Amzn-Trace-Id'] = `${user_data.merchantCode}`;
        }
      }
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );



